import { Flex, Label } from '@zeal/web-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import LaptopSVG from './assets/laptop.svg';
// @ts-ignore
import BackgoundSVG from './assets/background.svg';

export default function MainCard({ children }: { children?: React.ReactNode }) {
	const { t } = useTranslation('magicLink');

	return (
		<Flex
			isStretched
			style={{
				height: '708px',
			}}
		>
			<Flex
				className="rounded-l-2xl relative"
				style={{
					width: '45.45%',
					gap: '60px',
				}}
				isColumn
				align="center"
				justify="center"
			>
				<div
					style={{
						position: 'absolute',
						top: 0,
						left: 0,
						width: '100%',
						height: '100%',
						backgroundImage: `url(${BackgoundSVG})`,
						backgroundSize: 'cover',
						backgroundPosition: 'center',
						zIndex: -1,
						borderTopLeftRadius: '16px',
						borderBottomLeftRadius: '16px',
					}}
				/>
				<Flex
					style={{
						width: '402px',
						height: '238px',
					}}
				>
					<img src={LaptopSVG} />
				</Flex>
				<Flex isColumn gap="2" align="center" justify="center">
					<Label.Big600 variant="white" textAlign="center">
						{t('main_card_title')}
					</Label.Big600>
					<Label.Thin300 variant="white" textAlign="center">
						{t('main_card_description')}
					</Label.Thin300>
				</Flex>
			</Flex>
			<Flex
				className="bg-surface-default rounded-r-2xl"
				isColumn
				align="center"
				justify="center"
				style={{
					width: '54.55%',
					borderTopRightRadius: '16px',
					borderBottomRightRadius: '16px',
				}}
			>
				{children}
			</Flex>
		</Flex>
	);
}

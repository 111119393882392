import { Flex, Label } from '@zeal/web-ui';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
// @ts-ignore
import TierBackground from './tier-background.svg';

export default function CardlinkLogo() {
	const { t } = useTranslation('common');

	return (
		<Flex
			align="center"
			justify="center"
			className="py-1 px-3 rounded-[4px] h-8 relative"
			style={{
				minWidth: '100px',
			}}
		>
			<div
				style={{
					position: 'absolute',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					borderRadius: '4px',
					backgroundImage: `url(${TierBackground})`,
					backgroundSize: 'cover',
					backgroundPosition: 'center',
				}}
			/>
			<Flex
				className="z-[1] w-full"
				gap="1"
				align="center"
				justify="center"
				style={{ gap: '2px' }}
			>
				<Label.Mid300 variant="secondary" whitespace="nowrap">
					<Flex align="center" justify="center" className="w-full">
						<Trans
							i18nKey={t('cardlink')}
							className="flex items-center wrap-0"
							components={{
								separator: (
									<div
										style={{
											width: '16px',
											height: '12px',
											margin: '0 2px',
											background:
												'linear-gradient(90deg, #00DFABFF, #00DFAB00)',
											zIndex: 1,
										}}
									/>
								),
							}}
						/>
					</Flex>
				</Label.Mid300>
			</Flex>
		</Flex>
	);
}

import React, { useCallback, memo, useMemo } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

import { Button, Flex, Icon, Label, InlineLoading } from '@zeal/web-ui';
import { IconNames } from '@zeal/web-ui/dist/Icon';
import { IconColors } from '@zeal/web-ui/dist/Icon';

export type IconButtonProps = {
	icon: IconNames;
	label: string;
	onClickFunc: Function;
	color?: IconColors;
	isLoading?: boolean;
};

export default function IconButton(props: IconButtonProps) {
	const { icon, label, onClickFunc, color, isLoading } = props;

	return (
		<Button
			variant="transparent"
			onClick={onClickFunc}
			className="px-3 py-2 hover:shadow-sm hover:bg-gray-100 justify-start"
			disabled={isLoading}
		>
			{!isLoading ? (
				<Flex isStretched={true} justify="start" align="center" gap="sm">
					<Icon colorVariant={color} iconName={icon} variant={'regular'} />
					<Label style={color == 'danger' ? { color: '#ef4444' } : {}}>
						{label}
					</Label>
				</Flex>
			) : (
				<InlineLoading
					hide={!isLoading}
					styledClass={tw`hover:shadow-sm flex gap-1 items-center`}
				/>
			)}
		</Button>
	);
}

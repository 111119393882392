import { Flex, Label } from '@zeal/web-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import TierBackground from './tier-background.svg';

export default function BusinessLogo() {
	const { t } = useTranslation('common');

	return (
		<Flex
			align="center"
			justify="center"
			className="py-1 px-3 rounded-[4px] h-8 relative"
			style={{
				minWidth: '100px',
			}}
		>
			<div
				style={{
					position: 'absolute',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					borderRadius: '4px',
					backgroundImage: `url(${TierBackground})`,
					backgroundSize: 'cover',
					backgroundPosition: 'center',
				}}
			/>
			<Label.Mid300 variant="white" className="z-[1]">
				{t('business')}
			</Label.Mid300>
		</Flex>
	);
}
